import React from "react"
import logo_romb from "./images/logo_romb.png"

export default class LoadingScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
    }
  }

  //   componentDidMount() {
  //     setTimeout(() => {
  //       this.setState({ loading: false })
  //     }, 2000)
  //   }

  render() {
    const { loading } = this.state
    return (
      <div id="loading" style={{ display: this.state.style.display }}>
        <img id="loading-image" src={logo_romb} alt="Ładowanie..." />
        <div className="loader" id="loader"></div>
      </div>
    )
  }
}
