import "./loginStyle.css"
import React, { useRef } from "react"
import logo from "./logo.png"
import TextField from "@mui/material/TextField"
import * as adres from "./adresy.js"

let port = adres.config.url

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "polski"
} else {
  jezyk = "onucowy"
}

const Login = () => {
  const ref = useRef()
  const [utilsy, setUtilsy] = React.useState([])
  const [pin, setPin] = React.useState("")
  const [zaloguj, setZaloguj] = React.useState("")

  React.useEffect(() => {
    fetch(port + "/utils", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        //console.log(response)
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        console.log(data)
        setUtilsy(data)
      })
  }, [])

  React.useEffect(() => {
    let pinTemp  
    utilsy.find((x) => {if (x.id === "pin") 
    {pinTemp = x.value[jezyk]}})
    let zalogujTemp
    utilsy.find((x) => {if (x.id === "zaloguj")
    {zalogujTemp = x.value[jezyk]}})

    setPin(pinTemp ?? "PIN")
    setZaloguj(zalogujTemp ?? "Zaloguj")
  }, [utilsy])

  React.useEffect(() => {
    const submit = document.getElementById("btnSubmit-login")

    submit.addEventListener("click", (e) => {
      e.preventDefault()
      console.dir(ref)

      fetch(port + "/checkPin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pin: ref.current.lastChild.firstChild.value }),
      })
        .then((response) => {
          console.log(response)
          if (response.ok) {
            return response.json()
          }
          throw response
        })
        .then((data) => {
          console.log(data)
          if (data.PIN === null) {
            alert("Nieprawidłowy pin!")
          } else {
            localStorage.setItem("pin", ref.current.lastChild.firstChild.value)
            window.location.href = "home"
          }
        })
    })
  }, [])



  return (
    <div id="id01-login" className="modal-login">
      <form
        className="modal-content-login animate-login"
        id="forma-login"
        method="post"
      >
        <div className="imgcontainer-login">
          <img className="logo-login" src={logo} alt="logo" />
        </div>
        <div className="container-login">
          <TextField
            variant="outlined"
            label={pin}
            name="pin"
            id="pin-login"
            ref={ref}
            type="number"
          />

          <button type="button" id="btnSubmit-login">
            {zaloguj}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Login
