import React from 'react'
import PropTypes from 'prop-types'

export default class IndeterminableCheckbox extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      ...this.props,
      status: this.props.indeterminate ? 0 : 1
    }
  }

  componentDidMount () {
    if (!this.state.status) {
      this.actualizeStatus(localStorage.getItem(this.state.name) ? parseInt(localStorage.getItem(this.state.name)) : 0)
      this.el.indeterminate = true
    }
  }

  changeStatus () {
    const status = this.state.status;
    if (status < 2) {
      let newState = status + 1;
      return this.setState({status: newState}, () => this.handleChangeProp())
    } else {
      return this.setState({status: 1}, () => this.handleChangeProp())
    }
  }

  handleChangeProp () {
    const status = this.state.status

    switch (status) {
      case 1:
        this.el.indeterminate = false;
        this.el.checked = true;
        break;
      case 2:
        this.el.indeterminate = false;
        this.el.checked = false;
        break;
      default:
        this.el.indeterminate = true;
        this.el.checked = false;
        break;
    }

    return this.props.change(status)
  }

  actualizeStatus (status) {
    return this.setState({status}, () => this.handleChangeProp())
  }

  render () {
    return (
     // console.log(this.state.status),
      <input
        name={this.state.name}
        type='checkbox'
        ref={el => this.el = el}
        onChange={() => this.changeStatus()}
        status= {this.state.status}  
        id={this.state.id}
        mode='indeterminate'
      />
    )
  }
}

IndeterminableCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
  change: PropTypes.func,
  name: PropTypes.string,
  status: PropTypes.number,
  id: PropTypes.string,
  mode: PropTypes.string
};

IndeterminableCheckbox.defaultProps = {
  indeterminate: true
};