import "../truckStyle.css"
import React, { useState, useRef } from "react"
import logo from "../logo.png"

import TextField from '@mui/material/TextField'
import * as adres from "../adresy.js"

let port = adres.config.url

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "polski"
} else {
  jezyk = "onucowy"
}

const Truck = () => {
  const ref = useRef()
  const [utilsy, setUtilsy] = React.useState([])
  const [rej, setRej] = React.useState("")
  const [dalej, setDalej] = React.useState("")

  React.useEffect(() => {
    fetch(port + "/utils", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        //console.log(response)
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        console.log(data)
        setUtilsy(data)
      })
  }, [])

  React.useEffect(() => {
    let rejTemp
    utilsy.find((x) => {
      if (x.id === "rej") {
        rejTemp = x.value[jezyk]
      }
    })
    let dalejTemp
    utilsy.find((x) => {
      if (x.id === "dalej") {
        dalejTemp = x.value[jezyk]
      }
    })

    setRej(rejTemp ?? "Nr rejestracyjny")
    setDalej(dalejTemp ?? "Dalej")
  }, [utilsy])

  React.useEffect(() => {
    const submit = document.getElementById("btnSubmit-truck")

    submit.addEventListener("click", (e) => {
      console.log(ref.current.lastChild.firstChild.value)

      fetch(port+"/checkTrailer", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rej: ref.current.lastChild.firstChild.value }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw response
        })
        .then((data) => {
          console.log(data)
          if(data.RegNo === null)
          {
            alert("Nieprawidłowy numer rejestracyjny!")
          }
          else{
            localStorage.setItem("nrRej", ref.current.lastChild.firstChild.value)
            window.location.href="/formTrailer"
          }
        })
    })
  }, [])

  return (
    <div id="id01-truck" className="modal-truck">
      <form className="modal-content-truck animate-truck" id="forma-truck" method="post">
        <div className="imgcontainer-truck">
          <img className="logo-truck" src={logo} alt="logo" />
        </div>
        <div className="container-truck">
          {/* <label htmlFor="rej-truck">
            <b>Podaj nr rejestracyjny samochodu</b>
          </label> */}
          <TextField 
            variant="outlined"
            label={rej}
            type="text"
            name="rej"
            id="rej-truck"
            ref={ref}
          />

          <button type="button" id="btnSubmit-truck">
           {dalej}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Truck
