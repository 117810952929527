import "./style.css"
import React, { useState, useRef } from "react"
import Pytanie from "./names.json"
import LoadingScreen from "./loadingScreen.js"
import logo from "./logo.png"
import opony from "./images/opony.png"
import * as zdjecia from "./images/foto.js"
import ImageCompressor from "js-image-compressor"
import { saveAs } from "file-saver"
import { useSwipeable } from "react-swipeable"
import heic2any from "heic2any"
import IndeterminableCheckbox from "./checkbox.js"
import Sent from "./wyslano.js"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

//paczki do datePickera
import Stack from "@mui/material/Stack"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

import * as adres from "./adresy.js"

let port = adres.config.url
let pytanka = {}

let questions = new Object()
const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "polski"
} else {
  jezyk = "onucowy"
}

let addedFiles = new Object()

function heic2Jpg(pliczek) {
  return new Promise((resolve, reject) => {
    heic2any({ blob: pliczek, toType: "image/jpeg", quality: 0.5 }).then(
      function (data) {
        resolve(data)
      }
    )
  })
}

function czyOstatnia(index) {
  const ostatniaStrona = document
    .querySelectorAll(".pagination")[0]
    .lastElementChild.id.split("-")[1]

  if (parseInt(ostatniaStrona) === parseInt(index)) {
    document.getElementById("dalej").style.display = "none"
    document.getElementById("wyslij").style.display = "flex"
  } else {
    document.getElementById("dalej").style.display = "flex"
    document.getElementById("wyslij").style.display = "none"
  }
}

//<--importowanie zdjęć
function importAll(r) {
  let images = {}
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item)
  })
  return images
}
//-->

const fotosy = importAll(
  require.context("./images", false, /\.(png|jpe?g|svg)$/)
)

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const scrollPaginator = () => {
  const paginator = document.getElementById(`paginator`)
  const kontener = document.getElementsByClassName(`container`)[0]

  const elementWidth = document.querySelector(".active").offsetWidth
  const pageNumber = parseInt(
    document.querySelector(".active").firstChild.textContent
  )

  paginator.scrollTo({
    left: pageNumber * elementWidth - kontener.offsetWidth / 2,
    behavior: "smooth",
  })
}

function kompresja(pliku, nazwa, klucz) {
  var options = {
    file: pliku,
    quality: 0.3,
    mimeType: "image/webp",
    maxWidth: 4000,
    maxHeight: 4000,
    convertSize: Infinity,
    loose: true,
    redressOrientation: false,

    // Callback before compression
    beforeCompress: function (result) {
      // console.log("Image size before compression:", result.size)
      // console.log("mime type:", result.type)
    },

    // Compression success callback
    success: function (result) {
      // console.log("Image size after compression:", result.size)
      // console.log("mime type:", result.type)
      // console.log(
      //   "Actual compression ratio:",
      //   (((pliku.size - result.size) / pliku.size) * 100).toFixed(2) + "%"
      // )

      const plik = new File([result], nazwa + ".webp", {
        type: result.type,
        lastModified: Date.now(),
        height: result.height,
        width: result.width,
      })
      // console.log(plik)

      addedFiles[klucz] = plik
      //save file on local disk
      //saveAs(plik, nazwa)
    },
  }

  new ImageCompressor(options)
}

const validationCheck = (strona) => {
  let valid = true
  let toSend = new Array()
  const page = document.getElementById(`strona-${strona}`)
  const pola = Array.from(document.querySelectorAll(`.page-${strona}`))
  const errors = []
  pola.forEach((el) => {
    const fields = Array.from(el.querySelectorAll("input"))
    const uwagi = Array.from(el.querySelectorAll("textarea"))
    if (uwagi.length > 0) {
      toSend.push({ nazwa: "uwagi", wartosc: uwagi[0].value, type: "textarea" })
      localStorage.setItem("uwagi", uwagi[0].value)
    }
    let isChecked = false

    if (fields.length === 0) {
      //read from localStorage
      const bieznikLF = localStorage.getItem("bieznikLF")
      const bieznikRF = localStorage.getItem("bieznikRF")
      const bieznikLBZ = localStorage.getItem("bieznikLBZ")
      const bieznikRBZ = localStorage.getItem("bieznikRBZ")
      const bieznikLBW = localStorage.getItem("bieznikLBW")
      const bieznikRBW = localStorage.getItem("bieznikRBW")
      const markaLF = localStorage.getItem("markaLF")
      const markaRF = localStorage.getItem("markaRF")
      const markaLBZ = localStorage.getItem("markaLBZ")
      const markaRBZ = localStorage.getItem("markaRBZ")
      const markaLBW = localStorage.getItem("markaLBW")
      const markaRBW = localStorage.getItem("markaRBW")

      if (
        bieznikLF !== null &&
        bieznikRF !== null &&
        bieznikLBZ !== null &&
        bieznikRBZ !== null &&
        bieznikLBW !== null &&
        bieznikRBW !== null &&
        markaLF !== null &&
        markaRF !== null &&
        markaLBZ !== null &&
        markaRBZ !== null &&
        markaLBW !== null &&
        markaRBW !== null
      ) {
        toSend.push({ nazwa: "bieznikLF", wartosc: bieznikLF, type: "input" })
        toSend.push({ nazwa: "bieznikRF", wartosc: bieznikRF, type: "input" })
        toSend.push({ nazwa: "bieznikLBZ", wartosc: bieznikLBZ, type: "input" })
        toSend.push({ nazwa: "bieznikRBZ", wartosc: bieznikRBZ, type: "input" })
        toSend.push({ nazwa: "bieznikLBW", wartosc: bieznikLBW, type: "input" })
        toSend.push({ nazwa: "bieznikRBW", wartosc: bieznikRBW, type: "input" })
        toSend.push({ nazwa: "markaLF", wartosc: markaLF, type: "input" })
        toSend.push({ nazwa: "markaRF", wartosc: markaRF, type: "input" })
        toSend.push({ nazwa: "markaLBZ", wartosc: markaLBZ, type: "input" })
        toSend.push({ nazwa: "markaRBZ", wartosc: markaRBZ, type: "input" })
        toSend.push({ nazwa: "markaLBW", wartosc: markaLBW, type: "input" })
        toSend.push({ nazwa: "markaRBW", wartosc: markaRBW, type: "input" })
      } else {
        valid = false
        errors.push(el)
      }
    }

    if (fields.length === 1) {
      fields.forEach((e) => {
        if (e.value === "" && e.type !== "checkbox") {
          errors.push(el)
        } else if (
          e.type === "checkbox" &&
          e.attributes.mode.value === "indeterminate"
        ) {
          if (e.attributes.status.value === "0") {
            errors.push(el)
          } else {
            toSend.push({
              nazwa: e.name,
              wartosc: e.attributes.status.value === "1" ? true : false,
              type: "checkbox",
            })
            // localStorage.setItem(e.name, e.attributes.status.value === "1" ? true : false)
          }
        } else {
          if (e.value === "") {
            errors.push(el)
          } else {
            toSend.push({ nazwa: e.name, wartosc: e.value, type: e.type })
            localStorage.setItem(e.name, e.value)
          }
        }
      })
    }
    if (fields.length > 1) {
      if (
        fields.every(
          (el) =>
            el.type === "checkbox" &&
            el.attributes.mode.value !== "indeterminate" &&
            el.type !== "radio"
        )
      ) {
        fields.forEach((e) => {
          if (e.checked === true) {
            isChecked = true
          }
          toSend.push({ nazwa: e.name, wartosc: e.checked, type: e.type })
          localStorage.setItem(e.name, e.checked)
        })
        // if (isChecked === false) {
        //   errors.push(el)
        // }
      } else if (fields.every((el) => el.type === "radio")) {
        fields.forEach((e) => {
          if (e.checked === true) {
            isChecked = true
            toSend.push({ nazwa: e.name, wartosc: e.id, type: e.type })
            localStorage.setItem(e.name, e.id)
          }
        })
        if (isChecked === false) {
          errors.push(el)
        }
      } else {
        fields.forEach((e) => {
          if (e.id !== "dodatkoweZdjecia" && e.value === "") {
            errors.push(el)
          } else {
            toSend.push({ nazwa: e.name, wartosc: e.value, type: e.type })
            localStorage.setItem(e.name, e.value)
          }
          if (e.id === "dodatkoweZdjecia" && e.value !== "") {
            toSend.push({ nazwa: e.name, wartosc: e.value, type: e.type })
            localStorage.setItem(e.name, e.value)
          }
        })
      }
    }
  })

  function diffArray(arr1, arr2) {
    return arr1
      .concat(arr2)
      .filter((item) => !arr1.includes(item) || !arr2.includes(item))
  }

  const validate = diffArray(pola, errors)

  if (errors.length > 0) {
    valid = false
    errors.map((element) => {
      element.querySelectorAll("label").forEach((label) => {
        label.style.color = "red"
      })
      page.firstChild.style.backgroundColor = "rgba(209, 14, 14, 0.54)"
      page.firstChild.style.color = "rgb(255, 255, 255)"
    })
  } else {
    page.firstChild.style.backgroundColor = "#EEFADF"
    page.firstChild.style.color = "#337ab7"
  }
  validate.map((element) => {
    element.querySelectorAll("label").forEach((label) => {
      label.style.color = "green"
    })
  })

  return { v: valid, arr: toSend }
}

const validateAll = () => {
  document.getElementById("loading").style.display = "flex"
  let formData = new FormData()

  let isValidAll = true

  const pages = Array.from(document.querySelectorAll(".pagination")[0].children)
  let fullArray = new Array()
  pages.forEach((el) => {
    fullArray.push(validationCheck(el.id.split("-")[1]).arr)
    let isValid = validationCheck(el.id.split("-")[1]).v
    if (isValid === false) {
      isValidAll = false
    }
  })
  Object.keys(addedFiles).forEach((key) => {
    fullArray.push([{ nazwa: key, wartosc: addedFiles[key], type: "file" }])
  })
  fullArray = fullArray.flat()

  formData.append("RegNo", localStorage.getItem("nrRej"))
  formData.append("RegNo", "string")
  formData.append("WorkerID", localStorage.getItem("pin"))
  formData.append("WorkerID", "number")
  formData.append("TypPojazdu", "0")
  formData.append("TypPojazdu", "number")
  formData.append("Typ", localStorage.getItem("protokolType"))
  formData.append("Typ", "number")

  const replacements = {
    ą: "a",
    Ą: "A",
    ć: "c",
    Ć: "C",
    ę: "e",
    Ę: "E",
    ł: "l",
    Ł: "L",
    ń: "n",
    Ń: "N",
    ó: "o",
    Ó: "O",
    ś: "s",
    Ś: "S",
    ź: "z",
    Ź: "Z",
    ż: "z",
    Ż: "Z",
  }

  fullArray.forEach((el) => {
    for (let replacement in replacements) {
      el.nazwa = el.nazwa.replace(
        new RegExp(replacement, "g"),
        replacements[replacement]
      )
      //pair[1] = pair[1].replace(new RegExp(replacement, "g"), replacements[replacement]);
    }
    formData.append(el.nazwa, el.wartosc)
    formData.append(el.nazwa, el.type)
  })

  // for (var pair of formData.entries()) {
  //      console.log(pair[0] + ", " + pair[1])
  //   }

  if (isValidAll === true) {
    fetch(port + "/zapis", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response //.json();
        }
        throw response
      })
      .then((data) => {
        document.getElementById("loading").style.display = "none"

        document.getElementById("wyslano").style.display = "flex"
        localStorage.clear()
      })
  } else {
    document.getElementById("loading").style.display = "none"
    alert("Wypełnij wszystkie pola")
  }
}

// const scaleImage = (coords, ref) => {
//   const x = ref.width
//   const y = ref.height
//   let kordy = new Array()
//   kordy = coords.split(",")
//   let kordyFull = new Array()
//   kordy.map((el) => {
//     if (kordy.indexOf(el) % 2 === 0) {
//       kordyFull.push(parseFloat(el) * x)
//     } else {
//       kordyFull.push(parseFloat(el) * y)
//     }
//   })
//   return kordyFull
// }

const Form = () => {
  const [page, setPage] = useState(1)
  const [paginacja, setPaginacja] = useState([])
  const [formaFull, setFormaFull] = useState([])
  const [open, setOpen] = useState(false)
  const [oponki, setOponki] = useState([])
  const [utilsy, setUtilsy] = React.useState([])
  const [modalOpony, setModalOpony] = React.useState("")
  const [modalBieznik, setModalBieznik] = React.useState("")
  const [modalMarka, setModalMarka] = React.useState("")
  const [dalejButton, setDalejButton] = React.useState("")
  const [wybierzPlik, setWybierzPlik] = React.useState("")
  const [wyslijButton, setWyslijButton] = React.useState("")
  const [zatwierdzButton, setZatwierdzButton] = React.useState("")
  const [wsteczButton, setWsteczButton] = React.useState("")

  //zrobić state, który będzie przechowywał informacje o bieznikach
  const [tires, setTires] = React.useState({
    LF: {
      markaLF: localStorage.getItem("markaLF"),
      bieznikLF: localStorage.getItem("bieznikLF"),
    },
    RF: {
      markaRF: localStorage.getItem("markaRF"),
      bieznikRF: localStorage.getItem("bieznikRF"),
    },
    LBZ: {
      markaLBZ: localStorage.getItem("markaLBZ"),
      bieznikLBZ: localStorage.getItem("bieznikLBZ"),
    },
    LBW: {
      markaLBW: localStorage.getItem("markaLBW"),
      bieznikLBW: localStorage.getItem("bieznikLBW"),
    },
    RBZ: {
      markaRBZ: localStorage.getItem("markaRBZ"),
      bieznikRBZ: localStorage.getItem("bieznikRBZ"),
    },
    RBW: {
      markaRBW: localStorage.getItem("markaRBW"),
      bieznikRBW: localStorage.getItem("bieznikRBW"),
    },
  })
  //zrobić state, który będzie przechowywał informacje o markach

  React.useEffect(() => {
    fetch(port + "/utils", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        setUtilsy(data)
      })
  }, [])

  React.useEffect(() => {
    let modalOponyTemp
    utilsy.find((x) => {
      if (x.id === "modal_opona") {
        modalOponyTemp = x.value[jezyk]
      }
    })
    let bieznikTemp
    utilsy.find((x) => {
      if (x.id === "modal_bieznik") {
        bieznikTemp = x.value[jezyk]
      }
    })
    let markaTemp
    utilsy.find((x) => {
      if (x.id === "modal_marka") {
        markaTemp = x.value[jezyk]
      }
    })
    let dalejTemp
    utilsy.find((x) => {
      if (x.id === "dalejButton") {
        dalejTemp = x.value[jezyk]
      }
    })
    let wyslijTemp
    utilsy.find((x) => {
      if (x.id === "wyslij") {
        wyslijTemp = x.value[jezyk]
      }
    })
    let wybierzPlikTemp
    utilsy.find((x) => {
      if (x.id === "wybierz_plik") {
        wybierzPlikTemp = x.value[jezyk]
      }
    })
    let zatwierdzButtonTemp
    utilsy.find((x) => {
      if (x.id === "zatwierdz") {
        zatwierdzButtonTemp = x.value[jezyk]
      }
    })
    let wsteczButtonTemp
    utilsy.find((x) => {
      if (x.id === "wstecz") {
        wsteczButtonTemp = x.value[jezyk]
      }
    })

    setWsteczButton(wsteczButtonTemp ?? "Wstecz")
    setZatwierdzButton(zatwierdzButtonTemp ?? "Zatwierdź")
    setWybierzPlik(wybierzPlikTemp ?? "Wybierz plik...")
    setDalejButton(dalejTemp ?? "Dalej")
    setWsteczButton(wsteczButtonTemp ?? "Wstecz")
    setModalOpony(modalOponyTemp ?? "Podaj wysokość bieżnika i markę opony")
    setModalBieznik(bieznikTemp ?? "Bieżnik")
    setModalMarka(markaTemp ?? "Marka")
    setWyslijButton(wyslijTemp ?? "Wyślij")
  }, [utilsy])

  // React.useEffect(() => {

  // }, [wyslano, powrot])

  const handleClickOpen = (e) => {
    setOponki(e)
    setOpen(true)
  }

  const handleClose = () => {
    setTires((tires) => {
      return {
        ...tires,
        [oponki]: {
          ["marka" + oponki]: localStorage.getItem("marka" + oponki),
          ["bieznik" + oponki]: localStorage.getItem("bieznik" + oponki),
        },
      }
    })
    // console.log(tires)
    // console.log(oponki)
    setOpen(false)
    //refresh page
  }

  const ref = useRef(null)

  let strona = 1
  let foremka = []

  let i = 0

  const PageChange = (props) => {
    setPage((prevState) => {
      if (prevState >= 1 && prevState <= 17) {
        const newState = parseInt(prevState + props)

        if (prevState !== newState) {
          validationCheck(prevState)

          const stare = Array.from(
            document.getElementsByClassName("page-" + prevState)
          )

          const nowe = Array.from(
            document.getElementsByClassName("page-" + newState)
          )

          stare.forEach((el) => {
            el.style.display = "none"
          })

          nowe.forEach((el) => {
            el.style.display = "grid"
          })

          document.getElementById("strona-" + newState).classList.add("active")
          document.getElementById(
            "strona-" + newState
          ).firstChild.style.backgroundColor = "#337ab7"
          document.getElementById("strona-" + newState).firstChild.style.color =
            "#fff"
          document
            .getElementById("strona-" + prevState)
            .classList.remove("active")

          scrollPaginator()

          czyOstatnia(newState)

          if ("strona-" + newState === "strona-1") {
            document.getElementById("wstecz").style.display = "none"
          } else {
            document.getElementById("wstecz").style.display = "flex"
          }
        }

        return prevState, newState
      }
    })
  }

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (document.getElementsByClassName("active")[0].id.split("-")[1] != 1) {
        PageChange(-1)
      }
    },
    onSwipedLeft: (eventData) => {
      const ostatniaStrona = document
        .querySelectorAll(".pagination")[0]
        .lastElementChild.id.split("-")[1]
      if (
        document.getElementsByClassName("active")[0].id.split("-")[1] !=
        ostatniaStrona
      ) {
        PageChange(1)
      }
    },
  })

  //dalej
  React.useEffect(() => {
    const dalej = document.getElementById("dalej")

    dalej.addEventListener("click", (e) => {
      const ostatniaStrona = document
        .querySelectorAll(".pagination")[0]
        .lastElementChild.id.split("-")[1]

      if (
        document.getElementsByClassName("active")[0].id.split("-")[1] !=
        ostatniaStrona
      ) {
        PageChange(1)
      }
    })
  }, [])

  //wstecz
  React.useEffect(() => {
    const wstecz = document.getElementById("wstecz")

    wstecz.addEventListener("click", (e) => {
      const ostatniaStrona = document
        .querySelectorAll(".pagination")[0]
        .lastElementChild.id.split("-")[1]
      if (document.getElementsByClassName("active")[0].id.split("-")[1] != 1) {
        PageChange(-1)
      }
    })
  }, [])

  //wyslij
  React.useEffect(() => {
    const wyslij = document.getElementById("wyslij")

    wyslij.addEventListener("click", (e) => {
      //show Loading Screen
      validateAll()
    })
  }, [])

  //zmiana strony przez kliknięcie na nr strony
  React.useEffect(() => {
    const lista = document.querySelector("ul")

    lista.addEventListener("click", (e) => {
      strona = parseInt(e.target.lastChild.data)

      setPage((prevState) => {
        const newState = parseInt(e.target.lastChild.data)

        if (prevState !== newState) {
          validationCheck(prevState)

          const stare = Array.from(
            document.getElementsByClassName("page-" + prevState)
          )

          const nowe = Array.from(
            document.getElementsByClassName("page-" + newState)
          )

          stare.forEach((el) => {
            el.style.display = "none"
          })

          nowe.forEach((el) => {
            el.style.display = "grid"
          })

          e.target.parentElement.classList.add("active")
          e.target.style.backgroundColor = "#337ab7"
          e.target.style.color = "#fff"

          document
            .getElementById("strona-" + prevState)
            .classList.remove("active")

          czyOstatnia(e.target.parentElement.id.split("-")[1])

          if (e.target.parentElement.id === "strona-1") {
            document.getElementById("wstecz").style.display = "none"
          } else {
            document.getElementById("wstecz").style.display = "flex"
          }
        }
        return prevState, newState
      })
    })
  }, [])

  const MapAreas = (dane) => {
    return Object.keys(dane).map((el) => {
      const coordsArr = dane[el].split(",")
      return (
        <React.Fragment key={"opona" + el}>
          <g className="hover_group">
            <a onClick={() => handleClickOpen(el)}>
              <text x={coordsArr[0]} y={coordsArr[1]} fontSize="20">
                {/* {el} */}
              </text>
              <rect
                x={coordsArr[0]}
                y={coordsArr[1]}
                opacity={
                  tires[el][`bieznik${el}`] !== null &&
                  tires[el][`marka${el}`] !== null
                    ? "0.4"
                    : "0"
                }
                fill="green"
                width={coordsArr[2]}
                height={coordsArr[3]}
              ></rect>
            </a>
          </g>
        </React.Fragment>
      )
    })
    // }
  }
  

  React.useEffect(() => {
    //ref

    fetch(port + "/pytania", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        pytanka = data

        let listaStron = []
        let listaStronArray = []
        for (const key in pytanka) {
          const page = pytanka[key]
          if (listaStronArray.includes(page.page) === false) {
            listaStronArray.push(page.page)
            if (page.page === 1) {
              listaStron.push(
                <li
                  key={page.page}
                  className="active"
                  id={"strona-" + page.page}
                >
                  <a>{page.page}</a>
                </li>
              )
            } else {
              listaStron.push(
                <li key={page.page} id={"strona-" + page.page}>
                  <a>{page.page}</a>
                </li>
              )
            }
          }
        }
        //sort listaStronArray
        listaStronArray.sort((a, b) => a - b)
        //sort listaStron by page
        listaStron.sort((a, b) => a.key - b.key)
        setPaginacja(listaStron)

        //wczytanie formularza
        //for (const e in questions) {
        //paginacja
        // if (e === "1") {
        //   paginacja.push(
        //     <li key={e} className="active" id={"strona-" + e}>
        //       <a>{e}</a>
        //     </li>
        //   )
        // } else {
        //   paginacja.push(
        //     <li key={e} id={"strona-" + e}>
        //       <a>{e}</a>
        //     </li>
        //   )
        // }

        //uwagi
        for (const dane in pytanka) {
          const e = pytanka[dane].page
          // console.log(pytanka[dane])
          if (pytanka[dane].type === "textarea") {
            foremka.push(
              <div key={i} className={"row page-" + e}>
                <div className="center">
                  <label>{pytanka[dane].question[jezyk]}</label>
                  <div id="uwagi">
                    <textarea
                      defaultValue={
                        localStorage.getItem("uwagi")
                          ? localStorage.getItem("uwagi")
                          : ""
                      }
                      id="uwagi-textarea"
                      name="uwagi-textarea"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </div>
            )
          }
          //opony
          else if (pytanka[dane].type === "opony") {
            foremka.push(
              <div key={i} className={"row page-" + e}>
                <div className="center">
                  <label>{pytanka[dane].question[jezyk]}</label>
                  <figure id="truck" className="center">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 296 692"
                      preserveAspectRatio="xMinYMin meet"
                      id="samochodzik"
                    >
                      <image
                        width="296"
                        height="692"
                        xlinkHref={opony}
                        ref={ref}
                      />
                      {Object.keys(pytanka[dane].options[jezyk]).map((el) => {
                        const coordsArr =
                          pytanka[dane].options[jezyk][el].split(",")
                        return (
                          <React.Fragment key={"opona" + el}>
                            <g className="hover_group">
                              <a onClick={() => handleClickOpen(el)}>
                                <text
                                  x={coordsArr[0]}
                                  y={coordsArr[1]}
                                  fontSize="20"
                                >
                                  {/* {el} */}
                                </text>
                                <rect
                                  x={coordsArr[0]}
                                  y={coordsArr[1]}
                                  opacity={
                                    tires[el][`bieznik${el}`]&&
                                    tires[el][`marka${el}`]
                                      ? "0.4"
                                      : "0"
                                  } 
                                  fill="green"
                                  width={coordsArr[2]}
                                  height={coordsArr[3]}
                                ></rect>
                              </a>
                            </g>
                          </React.Fragment>
                        )
                      })}
                    </svg>
                  </figure>
                </div>
              </div>
            )
          } else if (pytanka[dane].type === "image") {
            foremka.push(
              <div key={i} className={"row page-" + e}>
                <div className="center">
                  <label>{pytanka[dane].question[jezyk]}</label>
                  <div className="samochodIMG">
                    <img
                      src={zdjecia[pytanka[dane].opis]}
                      alt={pytanka[dane].opis}
                    />
                  </div>
                  <div id="input-foto">
                    <label className="file">
                      <input
                        type="file"
                        id={pytanka[dane].id}
                        name={pytanka[dane].id}
                        accept="image/jpeg, image/heic, image/heif"
                        onChange={(e) => {
                          //change input text
                          document.getElementById(
                            "file-custom-" + pytanka[dane].opis
                          ).textContent = e.target.files[0].name

                          let pliku = e.target.files[0]
                          if (pliku.name.slice(-4) === "heic") {
                            heic2Jpg(pliku)
                              .then((data) => {
                                pliku = data
                                return data
                              })
                              .then(() => {
                                kompresja(
                                  pliku,
                                  pytanka[dane].image,
                                  e.target.name
                                )
                              })
                          } else {
                            kompresja(pliku, pytanka[dane].image, e.target.name)
                          }
                        }}
                      ></input>
                      <span
                        className="file-custom"
                        id={"file-custom-" + pytanka[dane].opis}
                      >
                        {wybierzPlik}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )
          } else if (pytanka[dane].type === "imageArray") {
            foremka.push(
              <div key={i} className={"row page-" + e}>
                <div className="center">
                  <label>{pytanka[dane].question[jezyk]}</label>
                  <div
                    id="input-foto"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "90%",
                    }}
                  >
                    {pytanka[dane].ilosc.map((el, i) => {
                      return (
                        <label className="file" key={i}>
                          <input
                            type="file"
                            id="dodatkoweZdjecia"
                            name={"dodatkoweZdjecia" + i}
                            accept="image/jpeg, image/heic, image/heif"
                            onChange={(e) => {
                              //change input text
                              document.getElementById(
                                "file-custom-" + pytanka[dane].opis + i
                              ).textContent = e.target.files[0].name

                              let pliku = e.target.files[0]
                              if (pliku.name.slice(-4) === "heic") {
                                heic2Jpg(pliku)
                                  .then((data) => {
                                    pliku = data
                                    return data
                                  })
                                  .then(() => {
                                    kompresja(
                                      pliku,
                                      pytanka[dane].image,
                                      e.target.name
                                    )
                                  })
                              } else {
                                kompresja(
                                  pliku,
                                  pytanka[dane].image,
                                  e.target.name
                                )
                              }
                            }}
                          ></input>
                          <span
                            className="file-custom"
                            id={"file-custom-" + pytanka[dane].opis + i}
                          >
                            {wybierzPlik}
                          </span>
                        </label>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          } else {
            foremka.push(
              <div key={i} className={"row page-" + e}>
                <div className="col1">
                  <label>{pytanka[dane].question[jezyk]}</label>
                </div>
                <div className="col2">
                  <a
                    className="zdjecie"
                    onClick={() => {
                      document.getElementById(
                        "id01" + pytanka[dane].id
                      ).style.display = "block"
                    }}
                  >
                    <img
                      id="zdjKolumna"
                      src={fotosy[pytanka[dane].image]}
                      alt={pytanka[dane].opis}
                    ></img>
                  </a>

                  <div id={"id01" + pytanka[dane].id} className="modal">
                    <div id="zdjSkala" className="modal-content animate">
                      <div className="imgcontainer">
                        <span
                          onClick={() =>
                            (document.getElementById(
                              "id01" + pytanka[dane].id
                            ).style.display = "none")
                          }
                          className="close"
                          title="Zamknij okno"
                        >
                          &times;
                        </span>
                      </div>
                      <div className="kontenerZdj">
                        <h3 id="tytulZdjecia">
                          {pytanka[dane].question[jezyk]}
                        </h3>
                        <div>{pytanka[dane].opis}</div>
                        <img
                          id="zdjPow"
                          src={fotosy[pytanka[dane].image]}
                          alt={pytanka[dane].opis}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col3">
                  {pytanka[dane].type === "radio" && (
                    <div className="radio-holder">
                      <input
                        type={pytanka[dane].type}
                        name={pytanka[dane].id}
                        id={pytanka[dane].id + "tak"}
                      />
                      <label htmlFor={pytanka[dane].id + "tak"}>Tak</label>
                      <input
                        type={pytanka[dane].type}
                        name={pytanka[dane].id}
                        id={pytanka[dane].id + "nie"}
                      />
                      <label htmlFor={pytanka[dane].id + "nie"}>Nie</label>
                    </div>
                  )}
                  {pytanka[dane].type === "radio2" &&
                    pytanka[dane].options[jezyk].map((el) => {
                      return (
                        <React.Fragment key={"input" + el}>
                          <div className="radio-holder">
                            <input
                              defaultChecked={
                                localStorage.getItem(
                                  pytanka[dane].question[jezyk]
                                )
                                  ? localStorage.getItem(
                                      pytanka[dane].question[jezyk]
                                    ) === el
                                    ? true
                                    : false
                                  : ""
                              }
                              type="radio"
                              onChange={(e) => {
                                localStorage.setItem(
                                  pytanka[dane].question[jezyk],
                                  e.target.id
                                )
                              }}
                              name={pytanka[dane].id}
                              id={el}
                            />
                            <label htmlFor={pytanka[dane].id + el}>{el}</label>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  {pytanka[dane].type === "date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <TextField
                          id={pytanka[dane].id}
                          name={pytanka[dane].id}
                          type={
                            pytanka[dane].options[jezyk] === "month"
                              ? "month"
                              : "date"
                          }
                          //format of date
                          inputProps={{
                            format:
                              pytanka[dane].options[jezyk] === "day"
                                ? "dd-MM-yyyy"
                                : "MM-yyyy",
                          }}
                          sx={{ width: 220 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={
                            localStorage.getItem(pytanka[dane].id)
                              ? localStorage.getItem(pytanka[dane].id)
                              : ""
                          }
                          onChange={(e) => {
                            localStorage.setItem(
                              pytanka[dane].id,
                              e.target.value
                            )
                          }}
                        />
                      </Stack>
                    </LocalizationProvider>
                  )}
                  {pytanka[dane].type === "number" && (
                    <React.Fragment>
                      <div className="numbery">
                        <input
                          defaultValue={
                            localStorage.getItem(pytanka[dane].id)
                              ? localStorage.getItem(pytanka[dane].id)
                              : ""
                          }
                          onChange={(e) => {
                            localStorage.setItem(e.target.name, e.target.value)
                          }}
                          className="numberki"
                          type={pytanka[dane].type}
                          name={pytanka[dane].id}
                        />
                        <span className="jednostka">
                          {pytanka[dane].jednostka}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                  {pytanka[dane].type === "checkbox" &&
                    (Object.keys(pytanka[dane].options[jezyk]).length > 1 ? (
                      Object.keys(pytanka[dane].options[jezyk]).map((el) => {
                        return (
                          <React.Fragment
                            key={"checkbox" + pytanka[dane].options.polski[el]}
                          >
                            <div className="checkboxik">
                              <input
                                defaultChecked={
                                  localStorage.getItem(
                                    pytanka[dane].options.polski[el]
                                  )
                                    ? localStorage.getItem(
                                        pytanka[dane].options.polski[el]
                                      ) === "true"
                                      ? true
                                      : false
                                    : false
                                }
                                onChange={(e) => {
                                  localStorage.setItem(
                                    e.target.id,
                                    e.target.checked
                                  )
                                }}
                                type="checkbox"
                                name={pytanka[dane].options.polski[el]}
                                id={pytanka[dane].options.polski[el]}
                                mode="standard"
                              />
                              <label htmlFor={pytanka[dane].options.polski[el]}>
                                {pytanka[dane].options[jezyk][el]}
                              </label>
                            </div>
                          </React.Fragment>
                        )
                      })
                    ) : (
                      <React.Fragment key={"checkbox"}>
                        <div className="checkboxik">
                          <IndeterminableCheckbox
                            change={(val) =>
                              localStorage.setItem(pytanka[dane].id, val)
                            }
                            name={pytanka[dane].id}
                            indeterminate={true}
                            id={pytanka[dane].id}
                          />
                          <label htmlFor={pytanka[dane].id}></label>
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              </div>
            )
          }
          i++
        }
        setFormaFull(foremka)
      })
  }, [ref.current, utilsy, tires])

  return (
    <div className="container">
      <div className="logo">
        {/* <button key="wstecz" type="button" id="wstecz">
          {wsteczButton}
        </button> */}
        <Button variant="contained" id="wstecz">
          {wsteczButton}
        </Button>
        <img src={logo} alt="kociuk-logistic" className="logoIMG"></img>
        {/* <button key="dalej" type="button" id="dalej">
        </button> */}
        <Button variant="contained" id="dalej">
          {dalejButton}
        </Button>
        {/* <button key="wyslij" type="button" id="wyslij">
        </button> */}
        <Button variant="contained" id="wyslij">
          {wyslijButton}
        </Button>
      </div>
      <div id="paginator">
        <ul className="pagination">{paginacja} </ul>
      </div>
      <div {...handlers} className="forma">
        <form>{formaFull}</form>
      </div>
      <LoadingScreen style={{ display: "none" }} />
      <Sent style={{ display: "none" }} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{oponki}</DialogTitle>
        <DialogContent>
          <DialogContentText> {modalOpony} </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id={"marka" + oponki}
            label={modalMarka}
            type="email"
            fullWidth
            variant="standard"
            defaultValue={
              localStorage.getItem("marka" + oponki)
                ? localStorage.getItem("marka" + oponki)
                : ""
            }
            onChange={(e) => {
              localStorage.setItem(e.target.id, e.target.value)
            }}
          />
          <TextField
            margin="dense"
            id={"bieznik" + oponki}
            label={modalBieznik}
            type="number"
            fullWidth
            variant="standard"
            defaultValue={
              localStorage.getItem("bieznik" + oponki)
                ? localStorage.getItem("bieznik" + oponki)
                : ""
            }
            onChange={(e) => {
              localStorage.setItem(e.target.id, e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {zatwierdzButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Form
