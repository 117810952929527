import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './index.css';
import Form from './forma';
import Home from './home';
import Truck from './truck';
import Trailer from './naczepa/trailer';
import FormTrailer from './naczepa/forma';
import Login from './login';
import reportWebVitals from './reportWebVitals';

// let newLang = 'pl'
// document.documentElement.lang = newLang
const root = ReactDOM.createRoot(document.getElementById('root'));

const token = localStorage.getItem('pin');

root.render(
  
// <React.StrictMode>
<BrowserRouter>
<Routes> 
  <Route path="/" element={<Login />} />
  <Route path="home" element={token ? <Home/>: <Navigate replace to="/" /> } />
  <Route path="truck" element={token ? <Truck/>: <Navigate replace to="/" /> } />
  <Route path="trailer" element={token ? <Trailer/>: <Navigate replace to="/" /> } />
  <Route path="form" element={token ? <Form/>: <Navigate replace to="/" /> } />
  <Route path="formTrailer" element={token ? <FormTrailer/>: <Navigate replace to="/" /> } />
</Routes>
</BrowserRouter>
// </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();