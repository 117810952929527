import React from "react"
import logo_romb from "./images/logo_romb.png"
import * as zdjecia from "./images/foto.js"
import * as adres from "./adresy.js"

let port = adres.config.url

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "polski"
} else {
  jezyk = "onucowy"
}

export default class Sent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
    }
  }

  componentDidMount() {

    fetch(port + "/utils", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        //console.log(response)
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        console.log(data)
        let wyslanoTemp
        data.find((x) => {
          if (x.id === "wyslano") {
            wyslanoTemp = x.value[jezyk]
          }
        })
        let powrotTemp
        data.find((x) => {
          if (x.id === "powrot") {
            powrotTemp = x.value[jezyk]
          }
        })
        this.setState({ wys: wyslanoTemp, pow: powrotTemp})
      })

    const button = document.getElementById("wyslano_button")
    button.addEventListener("click", (e) => {
      window.location.href = "/home"
    }
    )
  }

  render() {
    return (
      <div id="wyslano" style={{ display: this.state.style.display }}>
        <div id="image">
          <img
            id="wyslano_image"
            src={zdjecia.done}
            alt="Wysłano" 
            height="200px" 
          />
        </div>
        <div id="wyslano_napis">{this.state.wys}</div>
        <div id="buttonik">
          <button id="wyslano_button" >{this.state.pow}</button>
        </div>
      </div>
    )
  }
}
